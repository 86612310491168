<template lang="pug">
.confirm-card
  q-card.no-shadow
    q-card-section.confirm-card-title
      span {{ message }}

    q-card-actions.button-group.row.justify-center
      q-btn.modal-form-cancel(flat, no-caps, :label="confirm_modal_locales.cancel", @click="confirm(false)")
      q-btn.modal-form-submit(flat, no-caps, :label="confirm_modal_locales.submit", @click="confirm(true)")
</template>

<script>
import gridButtonGroup from "../grid/gridButtonGroup";
import gridFilters from "../grid/gridFilters";
import Profile from "../../view/profile";

export default {
  components: {},
  props: {
    parentData: { type: Object, default: () => {} },
  },
  data: function () {
    return {
      message: this.parentData.message,
    };
  },

  computed: {
    confirm_modal_locales() {
      return this.locales.confirm_modal[this.current_locale];
    },
  },

  created() {},

  mounted() {},

  methods: {
    confirm(val) {
      this.$emit("confirm", val);
    },
  },
};
</script>

<style lang="scss">
@import "../../../assets/styles/forms/confirm-modal";
</style>
